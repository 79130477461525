.swiper-container {
    width: 100%;
    /* min-height: 90vh; */
    min-height: 75vh;
    height: 100%;
    padding: 1rem;
    max-width: 1120px;
}
.swiper-pagination {
    margin: 0.1rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, max-content);
    column-gap: 2rem;
    align-items: center;
    justify-content: center;
    position: static;
    bottom: unset !important;
}
.swiper-pagination-bullet {
    width: 100% !important;
    height: auto !important;
    text-align: center;
    /* line-height: 20px; */
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.7rem;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem 0.2rem;
}
.swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
}