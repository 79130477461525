@import url(https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
	max-width: 1120px;
	margin: auto;
}
.fullWidth {
    width: 100%;
}
.biggerValue {
    font-weight: bolder;
    font-size: 200%;
}
.bigValue {
    font-weight: bolder;
    font-size: 150%;
}
.bordeDerecho {
    border-right: 5px solid #cab58a !important;
}
.centerText {
    text-align: center;
}
.smallPadding {
    padding: 0.5rem;
}

/* @import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
body,
html,
#root {
	/* height: 100%; */
	width: 100%;
	min-height: 60vw;
}
.chartContainer {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto 100%;
	height: auto;
	margin: 0px 1.5rem;
	font-family: "Alegreya";
	font-size: 1.4rem;
	/* min-height: 50rem; */
}
.bordeAbajo {
	border-bottom: 1px solid black !important;
	padding: 2rem;
}
.bordeArriba {
	border-top: 1px solid black !important;
	padding: 2rem;
}
.numeroPrincipal {
	font-size: 4rem;
	/* padding: 1rem; */
	border: 0;
	box-sizing: unset;
	text-align: center;
}
.fontSize08Rem {
	font-size: 0.8rem !important;
}
.subNumeroPrincipal {
	font-size: 2rem;
	font-weight: 100;
	border: 0;
	box-sizing: unset;
}
.chartContainer div {
	border: 0px solid blue;
}
.charts {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	grid-template-rows: 100%;
	grid-gap: 0.25rem;
	height: 100%;
	align-items: stretch;
}
.charts div {
	border: 0px solid red;
	place-self: center strecth;
	align-self: center;
}
.dobleChart {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 50%;
	text-align: center;
}
.chartGridTabla {
	display: grid;
	grid-template-columns: 1fr -webkit-min-content;
	grid-template-columns: 1fr min-content;
}
.gridCuatroColumnas {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1rem;
	gap: 1rem;
}
.mitad {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: start;
}
.mitad > :first-child {
	justify-content: end;
	border: 0px solid red;
	margin: 0px;
	margin-left: auto;
	min-width: 50%;
	/* max-width: 60%; */
}
.mitad > :last-child {
	justify-content: start;
	border: 0px solid blue;
	margin: 0px;
	margin-right: auto;
	min-width: 50%;
	/* max-width: 60%; */
}
.tricota {
	display: grid;
	/* grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 2fr) 2fr; */
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 0.25rem;
	gap: 0.25rem;
}
.grillaListadoDashboard {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem;
	padding: 1rem;
	font-weight: bolder;
	font-size: 1.5rem;
	align-items: center;
}
.grillaTabla {
	grid-template-columns: 2fr 1fr;
	
}

.grillaTablaProgressBar {
	grid-template-columns: 1.5fr 1fr 1fr;
	
}
.grillaListado {
	grid-template-columns: repeat(10,0.5fr);
	font-size: 1rem;
	grid-gap: 0rem;
	gap: 0rem;
	padding: 0rem;
	padding-bottom: 2.5rem;

}
.spanEnGrid {
	height: 1rem;
	width: 8rem;
	
}
.nameEnGrid {
	max-width: 250px;
}
.titleGrid {
	background-color: #cab58a;
	height: 2rem;
}

.valueEnGrid {
	text-align: center;
}
.chartTitle {
	margin-top: 1rem;
	font-weight: bolder;
	color: white;
	background-color: #cab58a;
	padding: 1rem;
	line-height: 1rem;
}
.clickable {
	cursor: pointer;
}
.subtitle {
	text-align: center;
	border: none;
	width: 100%;
	background-color: rgba(202, 181, 138, 0.5);
	padding: 0.5rem;
}
.chartJsLegend > h5 {
	border: 0px;
	text-align: center;
	font-weight: bold;
	padding: 0.5rem;
}
@-webkit-keyframes example {
	0% {
		width: 0px;
		height: 0px;
	}
	100% {
		width: 25%;
		height: 20%;
	}
}
@keyframes example {
	0% {
		width: 0px;
		height: 0px;
	}
	100% {
		width: 25%;
		height: 20%;
	}
}
.workingModal {
	position: absolute;
	top: 50%;
	left: 37.5%;
	background-color: white;
	z-index: 50000;
	border: 1px solid black;
	width: auto;
	height: auto;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
	animation: fadein 1s;
}
.workingModal span::before {
	content: "";
	/* background-image: url("../assets/Wedges-3.4s-200px.svg"); */
	background-size: contain;
	float: left;
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 5px;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */

.chartJsTitle {
	text-align: center;
}
.chartJsTitle > .title {
	font-size: larger;
	border: 0px solid;
	font-weight: 900;
}
/* O creamos 2 clases, 1 para el pie y otra para los otros charts o le buscamos un nro mas piola */
.chartJSContainer {
	/* max-width: 25vw; */
	max-height: 25vh;
	width: 95%;
	/* height: 85%; */
	margin: 0px min(auto, 2rem);
}
.cierre {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
}
.listadoTitle {
	text-align: center;
	background-color: #d2c9c2;
	border: none;
	width: 100%;
	padding: 0.5rem;
}
@media only screen and (max-width: 900px) {
	.tricota,
	.mitad,
	.charts,
	.cierre {
		grid-template-columns: 1fr !important;
		grid-template-rows: unset;
	}
	.mitad > :first-child,
	.mitad > :last-child {
		justify-content: center;
		margin: auto;
	}
	.swiper-pagination {
		grid-template-columns: repeat(3, -webkit-max-content) !important;
		grid-template-columns: repeat(3, max-content) !important;
		grid-gap: 1rem !important;
		gap: 1rem !important;
	}
	.grillaListadoDashboard {
		font-size: 1rem !important;
	}
	.numeroPrincipal {
		font-size: 2rem !important;
	}
	.subNumeroPrincipal,
	.chartTitle {
		font-size: 1rem !important;
	}
	.dobleChart {
		grid-template-rows: unset !important;
	}
	.chartJSContainer {
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		height: 100%;
		margin: 0px;
		/* display: grid; */
		/* grid-template-columns: 1fr 2fr; */
	}
}
.grillaNamedValue {
	display: grid;
	justify-content: end;
	font-size: medium;
}
.gridSeisColumnas {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	justify-content: center;
	width: 80%;
	margin: 2rem auto;
	grid-gap: 1rem;
	gap: 1rem;
}
.velocimetro {
	display: grid;
	justify-content: center;
	justify-items: center;
	grid-gap: 0.5rem;
	gap: 0.5rem;
}
.velocimetro .titulo,
.velocimetro .grande {
	font-weight: bolder;
	font-size: 1.5rem;
	border: 0px;
	margin-bottom: 0.5rem;
	text-align: center;
}
.grande {
	font-size: 2rem !important;
}
.rojo {
	color: red;
}
.verde {
	color: green;
}
@media only screen and (max-width: 1000px) {
	.gridSeisColumnas {
		grid-template-columns: repeat(4, 1fr);
	}
	.velocimetro .titulo {
		font-size: 1.1rem;
		margin-bottom: 0.5rem;
	}
	.gridCuatroColumnas {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media only screen and (max-width: 700px) {
	.gridSeisColumnas {
		grid-template-columns: repeat(2, 1fr);
	}
	.gridCuatroColumnas {
		grid-template-columns: repeat(2, 1fr);
	}
}
.grdVariacionInteranual {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem;
	grid-template-columns: 0.5fr 2fr;
}
.grdVariacionInteranual .variacionInteranual {
	background-color: black;
	color: white;
	border-radius: 50%;
	font-weight: bolder;
	align-self: center;
	width: 64px;
	height: 64px;
	align-self: center;
	padding-top: 14px;
}
.grdVariacionInteranual h4 {
	grid-column: 1 / -1;
}
.grilla {
	display: grid;
	grid-template-columns: 100%;
	justify-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	width: 100%;
}

.grillaAlignRight {
	display: grid;
	justify-items: flex-end;
	width: 100%;
}
.grillaTresColumnas {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1.15fr 1fr;
	justify-items: center;
}

.grillaCasosDemorados {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
}
.grillaCasosDemorados h4 {
	display: grid;
	grid-column: 1 / -1;
	text-align: center;
}
.grillaDatosHistoricos {
	display: grid;
	grid: repeat(3, 1fr) / repeat(2, 1fr);
	grid-auto-flow: row;
	display: grid;
	grid: 1fr 1fr 1fr / repeat(2, 1fr);
	grid-auto-flow: row;
	grid-gap: 1rem;
	gap: 1rem;
	min-height: 700px;
}
@media only screen and (max-width: 480px) {
	.grillaProyeccionAltas,
	.grillaTresColumnas,
	.grillaCasosDemorados {
		grid-template-columns: 100% !important;
	}
	.card {
		width: 100%;
	}
	.swiper-pagination {
		/* grid-auto-flow: column; */
		grid-template-columns: repeat(2, -webkit-max-content) !important;
		grid-template-columns: repeat(2, max-content) !important;
		grid-gap: 0.5rem !important;
		gap: 0.5rem !important;
		justify-content: center;
		justify-items: center;
	}
	.grillaDatosHistoricos {
		grid: -webkit-max-content / 100%;
		grid: max-content / 100%;
		/* height: 1000px; */
		min-height: 1000px;
	}
	.grillaDatosHistoricos .chartJSContainer {
		height: 175px;
	}
}

.swiper-container {
    width: 100%;
    /* min-height: 90vh; */
    min-height: 75vh;
    height: 100%;
    padding: 1rem;
    max-width: 1120px;
}
.swiper-pagination {
    margin: 0.1rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, -webkit-max-content);
    grid-template-columns: repeat(6, max-content);
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    align-items: center;
    justify-content: center;
    position: static;
    bottom: unset !important;
}
.swiper-pagination-bullet {
    width: 100% !important;
    height: auto !important;
    text-align: center;
    /* line-height: 20px; */
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.7rem;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem 0.2rem;
}
.swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
}
.creados {
    text-align: center;
    margin: 2rem auto;
}
.listadoTotal {
    margin: auto;
    text-align: center;
    padding: 1rem;
}
.chartTitle {
    text-align: center;
}
.listadoTitle {
    font-weight: bolder;
    color: white;
}
/* Esto podria no funcionar con los que llevan titulo */
.chartJSContainer > canvas {
    padding-bottom: 1rem;
    position: absolute;
    top: 10;
}

.lds-spinner {
    color: red;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-spinner div {
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}
.lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

:root {
    --main-color: #cab58a;
    --analogous-color-1: #d6cc87;
    --analogous-color-2: #e0cd8d;
    --analogous-color-3: #e0bc8d;
    --analogous-color-4: #d6a887;
}

.grillaCentro {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 10%;
}
.loader {
    border: 16px solid var(--main-color) !important;
    border-radius: 50%;
    border-top: 16px solid var(--analogous-color-1) !important;
    border-right: 16px solid var(--analogous-color-2) !important;
    border-bottom: 16px solid var(--analogous-color-3) !important;
    border-left: 16px solid var(--main-color) !important;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.cargando-modal {
    position: absolute;
    z-index: 999999;
    background: white;
}


@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

