.fullWidth {
    width: 100%;
}
.biggerValue {
    font-weight: bolder;
    font-size: 200%;
}
.bigValue {
    font-weight: bolder;
    font-size: 150%;
}
.bordeDerecho {
    border-right: 5px solid #cab58a !important;
}
.centerText {
    text-align: center;
}
.smallPadding {
    padding: 0.5rem;
}
