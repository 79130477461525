@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,800&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
body,
html,
#root {
	/* height: 100%; */
	width: 100%;
	min-height: 60vw;
}
.chartContainer {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto 100%;
	height: auto;
	margin: 0px 1.5rem;
	font-family: "Alegreya";
	font-size: 1.4rem;
	/* min-height: 50rem; */
}
.bordeAbajo {
	border-bottom: 1px solid black !important;
	padding: 2rem;
}
.bordeArriba {
	border-top: 1px solid black !important;
	padding: 2rem;
}
.numeroPrincipal {
	font-size: 4rem;
	/* padding: 1rem; */
	border: 0;
	box-sizing: unset;
	text-align: center;
}
.fontSize08Rem {
	font-size: 0.8rem !important;
}
.subNumeroPrincipal {
	font-size: 2rem;
	font-weight: 100;
	border: 0;
	box-sizing: unset;
}
.chartContainer div {
	border: 0px solid blue;
}
.charts {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	grid-template-rows: 100%;
	grid-gap: 0.25rem;
	height: 100%;
	align-items: stretch;
}
.charts div {
	border: 0px solid red;
	place-self: center strecth;
	align-self: center;
}
.dobleChart {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 50%;
	text-align: center;
}
.chartGridTabla {
	display: grid;
	grid-template-columns: 1fr min-content;
}
.gridCuatroColumnas {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;
}
.mitad {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
	align-items: start;
}
.mitad > :first-child {
	justify-content: end;
	border: 0px solid red;
	margin: 0px;
	margin-left: auto;
	min-width: 50%;
	/* max-width: 60%; */
}
.mitad > :last-child {
	justify-content: start;
	border: 0px solid blue;
	margin: 0px;
	margin-right: auto;
	min-width: 50%;
	/* max-width: 60%; */
}
.tricota {
	display: grid;
	/* grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 2fr) 2fr; */
	grid-template-columns: repeat(3, 1fr);
	gap: 0.25rem;
}
.grillaListadoDashboard {
	display: grid;
	gap: 1rem;
	padding: 1rem;
	font-weight: bolder;
	font-size: 1.5rem;
	align-items: center;
}
.grillaTabla {
	grid-template-columns: 2fr 1fr;
	
}

.grillaTablaProgressBar {
	grid-template-columns: 1.5fr 1fr 1fr;
	
}
.grillaListado {
	grid-template-columns: repeat(10,0.5fr);
	font-size: 1rem;
	gap: 0rem;
	padding: 0rem;
	padding-bottom: 2.5rem;

}
.spanEnGrid {
	height: 1rem;
	width: 8rem;
	
}
.nameEnGrid {
	max-width: 250px;
}
.titleGrid {
	background-color: #cab58a;
	height: 2rem;
}

.valueEnGrid {
	text-align: center;
}
.chartTitle {
	margin-top: 1rem;
	font-weight: bolder;
	color: white;
	background-color: #cab58a;
	padding: 1rem;
	line-height: 1rem;
}
.clickable {
	cursor: pointer;
}
.subtitle {
	text-align: center;
	border: none;
	width: 100%;
	background-color: rgba(202, 181, 138, 0.5);
	padding: 0.5rem;
}
.chartJsLegend > h5 {
	border: 0px;
	text-align: center;
	font-weight: bold;
	padding: 0.5rem;
}
@keyframes example {
	0% {
		width: 0px;
		height: 0px;
	}
	100% {
		width: 25%;
		height: 20%;
	}
}
.workingModal {
	position: absolute;
	top: 50%;
	left: 37.5%;
	background-color: white;
	z-index: 50000;
	border: 1px solid black;
	width: auto;
	height: auto;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}
.workingModal span::before {
	content: "";
	/* background-image: url("../assets/Wedges-3.4s-200px.svg"); */
	background-size: contain;
	float: left;
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 5px;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.chartJsTitle {
	text-align: center;
}
.chartJsTitle > .title {
	font-size: larger;
	border: 0px solid;
	font-weight: 900;
}
/* O creamos 2 clases, 1 para el pie y otra para los otros charts o le buscamos un nro mas piola */
.chartJSContainer {
	/* max-width: 25vw; */
	max-height: 25vh;
	width: 95%;
	/* height: 85%; */
	margin: 0px min(auto, 2rem);
}
.cierre {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
}
.listadoTitle {
	text-align: center;
	background-color: #d2c9c2;
	border: none;
	width: 100%;
	padding: 0.5rem;
}
@media only screen and (max-width: 900px) {
	.tricota,
	.mitad,
	.charts,
	.cierre {
		grid-template-columns: 1fr !important;
		grid-template-rows: unset;
	}
	.mitad > :first-child,
	.mitad > :last-child {
		justify-content: center;
		margin: auto;
	}
	.swiper-pagination {
		grid-template-columns: repeat(3, max-content) !important;
		gap: 1rem !important;
	}
	.grillaListadoDashboard {
		font-size: 1rem !important;
	}
	.numeroPrincipal {
		font-size: 2rem !important;
	}
	.subNumeroPrincipal,
	.chartTitle {
		font-size: 1rem !important;
	}
	.dobleChart {
		grid-template-rows: unset !important;
	}
	.chartJSContainer {
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		height: 100%;
		margin: 0px;
		/* display: grid; */
		/* grid-template-columns: 1fr 2fr; */
	}
}
.grillaNamedValue {
	display: grid;
	justify-content: end;
	font-size: medium;
}
.gridSeisColumnas {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	justify-content: center;
	width: 80%;
	margin: 2rem auto;
	gap: 1rem;
}
.velocimetro {
	display: grid;
	justify-content: center;
	justify-items: center;
	gap: 0.5rem;
}
.velocimetro .titulo,
.velocimetro .grande {
	font-weight: bolder;
	font-size: 1.5rem;
	border: 0px;
	margin-bottom: 0.5rem;
	text-align: center;
}
.grande {
	font-size: 2rem !important;
}
.rojo {
	color: red;
}
.verde {
	color: green;
}
@media only screen and (max-width: 1000px) {
	.gridSeisColumnas {
		grid-template-columns: repeat(4, 1fr);
	}
	.velocimetro .titulo {
		font-size: 1.1rem;
		margin-bottom: 0.5rem;
	}
	.gridCuatroColumnas {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media only screen and (max-width: 700px) {
	.gridSeisColumnas {
		grid-template-columns: repeat(2, 1fr);
	}
	.gridCuatroColumnas {
		grid-template-columns: repeat(2, 1fr);
	}
}
.grdVariacionInteranual {
	display: grid;
	gap: 1rem;
	grid-template-columns: 0.5fr 2fr;
}
.grdVariacionInteranual .variacionInteranual {
	background-color: black;
	color: white;
	border-radius: 50%;
	font-weight: bolder;
	align-self: center;
	width: 64px;
	height: 64px;
	align-self: center;
	padding-top: 14px;
}
.grdVariacionInteranual h4 {
	grid-column: 1 / -1;
}
.grilla {
	display: grid;
	grid-template-columns: 100%;
	justify-items: center;
	gap: 1rem;
	width: 100%;
}

.grillaAlignRight {
	display: grid;
	justify-items: flex-end;
	width: 100%;
}
.grillaTresColumnas {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1.15fr 1fr;
	justify-items: center;
}

.grillaCasosDemorados {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
}
.grillaCasosDemorados h4 {
	display: grid;
	grid-column: 1 / -1;
	text-align: center;
}
.grillaDatosHistoricos {
	display: grid;
	grid: repeat(3, 1fr) / repeat(2, 1fr);
	grid-auto-flow: row;
	display: grid;
	grid: 1fr 1fr 1fr / repeat(2, 1fr);
	grid-auto-flow: row;
	gap: 1rem;
	min-height: 700px;
}
@media only screen and (max-width: 480px) {
	.grillaProyeccionAltas,
	.grillaTresColumnas,
	.grillaCasosDemorados {
		grid-template-columns: 100% !important;
	}
	.card {
		width: 100%;
	}
	.swiper-pagination {
		/* grid-auto-flow: column; */
		grid-template-columns: repeat(2, max-content) !important;
		gap: 0.5rem !important;
		justify-content: center;
		justify-items: center;
	}
	.grillaDatosHistoricos {
		grid: max-content / 100%;
		/* height: 1000px; */
		min-height: 1000px;
	}
	.grillaDatosHistoricos .chartJSContainer {
		height: 175px;
	}
}
