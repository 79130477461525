.grillaCentro {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 10%;
}
.loader {
    border: 16px solid var(--main-color) !important;
    border-radius: 50%;
    border-top: 16px solid var(--analogous-color-1) !important;
    border-right: 16px solid var(--analogous-color-2) !important;
    border-bottom: 16px solid var(--analogous-color-3) !important;
    border-left: 16px solid var(--main-color) !important;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.cargando-modal {
    position: absolute;
    z-index: 999999;
    background: white;
}


@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
